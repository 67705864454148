import React from 'react';
import { Col, Row } from 'antd';

const RequiredFormLegend = () => (
    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Col>* required field</Col>
    </Row>
);

export default RequiredFormLegend;
