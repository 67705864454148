import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Row, Typography } from 'antd';

const { Text } = Typography;

const ObservationFormSection = ({
    label,
    description,
    hasRequired = false,
    defaultIsOpen = false,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const isOpenIcon = ['far', 'angle-down'];
    const isClosedIcon = ['far', 'angle-up'];

    const collapseIcon = (
        <FontAwesomeIcon icon={isOpen ? isOpenIcon : isClosedIcon} />
    );
    const title = <Text className='observation__section--title'>{label}</Text>;
    const requiredLabel = (
        <Text className='observation__section--required'>
            {!isOpen && !!hasRequired ? 'Required' : ''}
        </Text>
    );
    return (
        <div className='observation__section'>
            <div className='observation__section--header'>
                {/* / Give a larger, more accessible click radius */}
                <Button
                    className='observation__section--collapse'
                    onClick={() => setIsOpen(!isOpen)}
                    icon={collapseIcon}
                >
                    {title}
                </Button>
                <div>{requiredLabel}</div>
            </div>
            <div
                className={
                    isOpen
                        ? 'observation__section--children'
                        : 'observation__section--children hidden'
                }
            >
                <Row>
                    <span dangerouslySetInnerHTML={{ __html: description }} />
                </Row>
                {children}
            </div>
        </div>
    );
};

export default ObservationFormSection;
